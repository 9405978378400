import React, { useEffect, useState, useMemo } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import dayjs from 'dayjs';
import {
  Button,
} from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import {
  editEfficiency,
  getEfficiencies,
  delMultyEfficiency,
  exportEfficiencies,
  validateEfficiency,
  getEfficiency,
} from './api';
import { getDefaultSelection, getFields, getSelectedFields, dateRenderer } from './fields';
import Filters from './Filters';
import EditWindow from './EditModal/index';
import Confirm from '../widgets/Confirm';
import ErrorDialog from '../widgets/ErrorDialog';
import { DataTable, TableFooter } from '../widgets/DataTable';
import ExportButton from '../widgets/ExportButton';
import useRequest from '../../functions/useRequest';
import useTableRequest from '../../functions/useTableRequest';
import getFiltersWithInverted from '../../functions/getFiltersWithInverted';
import '../../stylesheets/efficiencyAdmin.css';

const initialFilters = {
  unloading_start_date_from: dayjs().startOf('day').subtract(7, 'day').toDate(),
  unloading_start_date_to: dayjs().endOf('day').toDate(),
  records_type: 'all',
};

const exportList = (fields) =>
  (user, updateTokens, oauthToken, { filters, sort }) =>
    exportEfficiencies(user, updateTokens, oauthToken, { filters, sort, fields });

const ConfirmDelBody = ({row, t}) => row && (
  <>
    <p>
      {t(
        'efficiencyAdmin.romevedMeasurement',
        {
          defaultValue: 'Delivery {{deliveryId}} on store {{storeId}} from {{warehouseId}} at {{createDate}}',
          deliveryId: row.delivery,
          storeId: row.site_name,
          warehouseId: row.warehouse_id,
          createDate: dateRenderer(row.create_date),
        }
      )}
    </p>
    {t('efficiencyAdmin.confirmDeleteText', 'Are you sure you want to delete this measurement?')}
  </>
);

const EfficiencyAdmin = ({ user, updateTokens }) => {
  const { t } = useTranslation();
  const { store, country } = useParams();
  const { search } = useLocation();
  const url = new URLSearchParams(search);
  const [editRow, setEditRow] = useState(null);
  const [delRow, setDelRow] = useState(null);
  const [actionError, setActionError] = useState(null);
  const [invertedFilters, setInvertedFilters] = useState({});
  const [checkedRows, setCheckedRows] = useState({});
  const [showConfirm, setShowConfirm] = useState(false);
  const [validatingId, setValidatingId] = useState(null);
  const [paramsId, setParamsId] = useState(url.get('id'))
  
  const searchParams = {
    site: url.get('site'),
    date_from: new Date(url.get('date_from')),
    date_to: new Date(url.get('date_to')),
    type: url.get('type'),
  };

  const [{
    loading: tableLoading,
    data,
    error,
    filters,
    pagination,
    sort,
    totals,
  }, fetchEfficiencies, setFilters, setPagination, setSort] = useTableRequest(
    getEfficiencies, {
      initialState: {
        filters: {
          country_id: searchParams.site ? undefined : country,
          site_ids: searchParams.site ? searchParams.site : (store ? [store] : undefined),
          unloading_start_date_from: searchParams.date_from ? searchParams.date_from : initialFilters.unloading_start_date_from,
          unloading_start_date_to: searchParams.date_to ? searchParams.date_to : initialFilters.unloading_start_date_to,
          records_type: searchParams.type ? searchParams.type : initialFilters.records_type,
        },
      },
    },
  );
  const [{ loading: saving }, doEditEfficiency] = useRequest(editEfficiency, {
    onError: setActionError,
  });
  const [{ loading: multyDeleting }, doMultyDelEfficiency] = useRequest(delMultyEfficiency, {
    onError: setActionError,
  });
  const [{ loading: validating }, validateMeasurement] = useRequest(validateEfficiency, {
    onError: setActionError,
  });
  const [{ data: efficiencyRecord }, getEfficiencyRecord] = useRequest(getEfficiency, {
    onError: setActionError,
  });
  const loading = tableLoading || saving || multyDeleting || validating;

  useEffect(() => {
    fetchEfficiencies(user, updateTokens, {
      filters: getFiltersWithInverted(filters, invertedFilters, true),
      pagination,
      sort,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, invertedFilters, pagination, sort]);

  useEffect(() => {
    if (delRow && delRow.id) {
      setShowConfirm(true);
      return;  
    }
  }, [delRow]);

  useEffect(() => {
    if (!paramsId) {
      return;
    }
    getEfficiencyRecord(user, updateTokens, paramsId);
  }, [paramsId]);

  useEffect(() => {
    if (!efficiencyRecord) {
      return;
    }
    setEditRow(efficiencyRecord);
  }, [efficiencyRecord]);

  const removeSelectedRows = () => {
    setShowConfirm(true);
  };

  const doRemoveRecords = async () => {
    let ids = Object.keys(checkedRows);
    if (delRow && delRow.id) {
      ids = [delRow.id];
      setDelRow(null);
    }
    await doMultyDelEfficiency(user, updateTokens, ids);
    setShowConfirm(false);
    setCheckedRows({});
    setFilters({ ...filters }); // to refetch
  };

  const hideConfirm = () => {
    setDelRow(null);
    setShowConfirm(false);
  };

  const askValidation = (id) => setValidatingId(id);

  const allFields = getFields({ t, askValidation });
  const [fieldsSelection, setFieldsSelection] = useState(getDefaultSelection(allFields));
  const fields = getSelectedFields({
    fields: allFields,
    selection: fieldsSelection,
    onEdit: setEditRow,
    onDel: setDelRow,
  });

  const memoizedExportReport = useMemo(() => exportList(fieldsSelection), [fieldsSelection]);

  return (
    <div className="p-2">
      <h3>
        <Trans i18nKey="efficiencyAdmin.navTitle">
          Records Edit
        </Trans>
      </h3>
      <Filters
        filters={filters}
        setFilters={setFilters}
        onClear={() => setFilters(initialFilters)}
        invertedFilters={invertedFilters}
        setInvertedFilters={setInvertedFilters}
        fields={allFields}
        fieldsSelection={fieldsSelection}
        setFieldsSelection={setFieldsSelection}
        user={user}
        updateTokens={updateTokens}
        removeButton={
          <>
            <Button
              size="sm"
              disabled={!Object.keys(checkedRows).length}
              className="icon-button filter-button"
              variant="danger"
              onClick={removeSelectedRows}
            >
              <Trans i18nKey="efficiencyAdmin.remove">Remove</Trans>
            </Button>
            <ExportButton
              size="sm"
              user={user}
              updateTokens={updateTokens}
              filters={filters}
              invertedFilters={invertedFilters}
              sort={sort}
              totalItems={totals && totals.items}
              exportReport={memoizedExportReport}
            />
          </>
        }
      />
      <DataTable
        rowKey="id"
        data={data}
        fields={fields}
        loading={loading}
        error={error}
        sort={sort}
        setSort={setSort}
        checkable={true}
        checkedRows={checkedRows}
        setCheckedRows={setCheckedRows}
      />
      <TableFooter
        pagination={pagination}
        totals={totals}
        setPagination={setPagination}
      />
      <EditWindow
        efficiency={editRow}
        onHide={() => setEditRow(null)}
        onSave={async (efficiency) => {
          await doEditEfficiency(
            user,
            updateTokens,
            editRow.site_id,
            editRow.id,
            efficiency,
          );
          setEditRow(null);
          setFilters({ ...filters }); // to refetch
        }}
      />
      <Confirm
        icon={<i className="modal-header-icon text-danger vtmn-icon_delete" />}
        title={t('efficiencyAdmin.confirmDelete', 'Confirm delete')}
        body={!delRow
          ? t('efficiencyAdmin.confirmMultyDeleteText', 'Are you sure you want to delete selected measurements?')
          : <ConfirmDelBody row={delRow} t={t} />
        }
        buttonOpts={{
          variant: 'danger',
          text: t('shared.delete', 'Delete')
        }}
        show={showConfirm}
        onHide={hideConfirm}
        loading={multyDeleting}
        onConfirm={doRemoveRecords}
      />
      <ErrorDialog error={actionError} setError={setActionError} />
      <Confirm
        show={!!validatingId}
        body={t('efficiencyAdmin.confirmValidate', 'Consider the record like valid? The record will be used for calculations of KPIs')}
        onHide={() => setValidatingId(null)}
        onConfirm={async () => {
          setValidatingId(null);
          await validateMeasurement(user, updateTokens, validatingId);
          setFilters({ ...filters }); // to refetch
        }}
      />
    </div>
  );
};

export default EfficiencyAdmin;
